import React from 'react';
import './bakemenuheader.css';
import kermabg from '../../../assets/biggallery/IMG_0873.JPG';

const Bakemenuheader = () => {
  return (
    <div>
      <div className="menu-background-header">
        <img src={kermabg} className="menu-background-image" alt="kerma-background" />
        <div className="header-text">
          <h1 className="tervetoula-kerma">Menu</h1>
        </div>
      </div>
    </div>
  );
};

export default Bakemenuheader;