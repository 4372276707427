import React, {useEffect} from 'react'
import AOS from 'aos'
import 'aos/dist/aos.css'
import './smallgallery.css';
import img1 from '../../assets/jarvispics/jarvis1.jpg';
import img2 from '../../assets/jarvispics/jarvis2.jpg';
import img3 from '../../assets/jarvispics/jarvis6.jpg';
import img4 from '../../assets/jarvispics/jarvis4.jpg';
import img5 from '../../assets/jarvispics/jarvis5.jpg';
import img6 from '../../assets/jarvispics/jarvis3.jpg';
import img7 from '../../assets/jarvispics/jarvis7.jpg';

const Smallgallery = () => {
  useEffect(()=> {
    AOS.init({ duration: 1000, once: true });
  }, []);

  return (
    <section className="kerma-gallery" id="kerma-gallery">
      <div className="gallery-container">
        <h1 className="gallery-header">Galleria</h1>
        <div className="gallery-images" data-aos="fade-up" data-aos-delay="100">
          <div>
            <img src={img1} alt="kerma-img1" />
          </div>
          <div>
            <img src={img2} alt="kerma-img2" />
          </div>
          <div className="big-stretch">
            <img src={img3} alt="kerma-img3" />
          </div>
          <div>
            <img src={img4} alt="kerma-img4" />
          </div>
          <div>
            <img src={img5} alt="kerma-img5" />
          </div>
          <div>
            <img src={img6} alt="kerma-img6" />
          </div>
          <div>
            <img src={img7} alt="kerma-img7" />
          </div>
        </div>
        <a href="/gallery" className="menu-cta">
        Näytä lisää
          </a>
      </div>
    </section>
  );
}

export default Smallgallery;
