import React, {useEffect} from 'react'
import './sponsored.css';
import Underberglogo from '../../assets/Underberg.png';
import AOS from 'aos'
import 'aos/dist/aos.css'

const Sponsored = () => {
  useEffect(()=> {
    AOS.init({ duration: 1000, once: true });
  }, []);

  return (
    <div className="sponsored-container">
      <div className="sponsored-content">
        <img src={Underberglogo} className="underberg-logo" alt="underberglogo" data-aos="fade-up" data-aos-delay="100"/>
      </div>
    </div>
  );
};

export default Sponsored;
