import React, {useEffect} from 'react'
import './menuinfo.css';
import coffeepng from '../../assets/coffee-04.png'
import AOS from 'aos'
import 'aos/dist/aos.css'
import img2 from '../../assets/jarvispics/jarvis2.jpg';


export const Menuinfo = () => {
  useEffect(()=> {
    AOS.init({ duration: 1000, once: true });
  }, []);
  
  return (
    <section className="shortmenu" id="shortmenu">
    <div className="menuinfo-container">
      <div className="menuinfo-header">
        <h1 className="our-menus">Meidän keittiö </h1>
      </div>
      <div className="wrapper-menu">
        <img src={img2} className="menu-picture" alt="menupicture" data-aos="fade-up" data-aos-delay="100"/>
        <div className="text-box" data-aos="fade-up" data-aos-delay="200">
          <h2>Tervetuloa Kermaan! </h2>
          <img src={coffeepng} className="coffee-png" alt="coffeepng" />
          <p className="kerma-content">Cafe & Bistro Kerma tuo Tahkolle alppihenkeä yhdistettynä savolaiseen rentouteen! Kermassa nautitaan herkullisia makuja ulkoilupäivän keskellä.</p>
          <p className="kerma-content">Valikoimasta löytyy maukkaita salaatteja, pieniä suolaisia ja makeita kahvileipiä. Kyytipojaksi sopivat laadukkaat kahvit, viinit sekä muut virkistävät ja raikkaat juomat. </p>
          <p className="kerma-content">Cafe & Bistro Kerma kuuluu samaan ravintolaperheeseen El Monten kanssa. Palvelemme kesällä Keskiviikko-Lauantai 12-16 Lämpimästi tervetuloa meille ja Tahkolle. </p>
          <a href="/menu" className="menu-cta">
          Näytä Ruokalista
          </a>
        </div>
      </div>
    </div>
    </section>
  );
};

export default Menuinfo;