import React from 'react'
import './news.css'
import img3 from '../../assets/biggallery/IMG_0873.JPG';

const News = () => {
  return (
    <section>
    <div className="news-container">
      <h1>uutiset</h1>
      <div className="news-box">
        <h5>16 July 2024</h5>
        <p>Kesällä palvelemme säävarauksella, katso mahdolliset poikkeukset Kerman Facebookista.</p>
          <img src={img3} alt="kerma-news" />
      </div>
    </div>
    </section>
  )
}

export default News