import React, {useEffect} from 'react'
import './comingsoon.css';
import kermainverted from '../../assets/kermainverted.png';
import { FaFacebook, FaInstagram, FaArrowAltCircleDown } from 'react-icons/fa';
import kermabg from '../../assets/biggallery/IMG_1028.JPG'
import AOS from 'aos'
import 'aos/dist/aos.css'

const Comingsoon = () => {
  useEffect(()=> {
    AOS.init({ duration: 1000, once: true });
  }, []);

  return (
    <section className="comingsoon" id="comingsoon">
    <div className="background-header">
    <img src={kermainverted} className="logo" alt="kerma-logo" />
      <img src={kermabg} className="background-image" alt="kerma-background" />
      </div>
      <div className="social-icons-container">
        <a href="https://www.facebook.com/cafebistrokerma/" target="_blank" rel="noopener noreferrer" data-aos="zoom-in" data-aos-delay="100" className="social-icons"><FaFacebook/></a>
        <a href="https://www.instagram.com/tahkonkerma/" target="_blank" rel="noopener noreferrer" data-aos="zoom-in" data-aos-delay="100" className="social-icons"><FaInstagram/></a>
      </div>
      <div className="arrow-container" data-aos="fade-up" data-aos-delay="100">
      <a href="#shortmenu"><FaArrowAltCircleDown className="arrow-icon" /></a>
      </div>
    </section>
  );
};

export default Comingsoon;