import React from 'react'
import './about.css'
import Bakemenuheader from '../headers/about-header/Aboutheader';
import Footer from '../../components/footer/Footer'
import Navbar from '../navbar/Navbar'

const About = () => {
  return (
    <section className="kerma-introduction" id="kerma-introduction">
    <div>
      <Navbar/>
    <Bakemenuheader/>
      <div className="introduction-container">
        <div className="introduction-header">
          <h1 className="kerma-tervetuloa">Tervetuloa!</h1>
        </div>
        <div className="introduction-content">
          <p className="kerma-content"> Cafe & Bistro Kerma tuo Tahkolle alppihenkeä yhdistettynä savolaiseen rentouteen! Kermassa nautitaan herkullisia makuja ulkoilupäivän keskellä. Valikoimasta löytyy maukkaita salaatteja, pieniä suolaisia ja makeita kahvileipiä.
            </p>
            <p className="kerma-content">
            Kyytipojaksi sopivat laadukkaat kahvit, viinit sekä muut virkistävät ja raikkaat juomat. Cafe & Bistro Kerma kuuluu samaan ravintolaperheeseen El Monten kanssa. 

            </p> 
            <p className="kerma-content">
            Palvelemme kesällä Keskiviikko - Lauantai klo 12 - 16
              </p>
        </div>
      </div>
      <Footer/>
    </div>
    </section>
  )
}

export default About