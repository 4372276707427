import React, { useState } from 'react';
import DrinkMenu from './drinkmenu/Drinkmenu';
// import FoodMenu from './Foodmenu/Foodmenu';
import './menulist.css';
import Bakemenuheader from '../headers/bakemenuheader/Bakemenuheader';
import Footer from '../../components/footer/Footer';
import Navbar from '../navbar/Navbar';

const RestaurantMenu = () => {
  const [selectedSection, setSelectedSection] = useState('drinks');

  return (
    <div className="menulist-container">
      <Navbar/>
      <Bakemenuheader />

      <div className="menu-buttons">
        {/*<button onClick={() => setSelectedSection('drinks')}>Juomat</button>*/}
        {/* <button onClick={() => setSelectedSection('foods')}>Ruoka</button> */}
      </div>

      {selectedSection === 'drinks' && <DrinkMenu />}
      {/* {selectedSection === 'foods' && <FoodMenu />} */}
    </div>
  );
};
<Footer/>

export default RestaurantMenu;
