import React from 'react';
import './closed.css';
import kermainverted from '../../assets/kermainverted.png';
import 'aos/dist/aos.css';
import img2 from '../../assets/jarvispics/newpic.jpg';

const Closed = () => {
  return (
    <section className="comingsoon" id="comingsoon">
      <div className="background-header">
        <img src={kermainverted} className="logo-closed" alt="kerma-logo" />
        <h4 className="closed-text">
          <span className="closed-kiitos">Tervehdys</span>
          <h3 className="sub-header"><span className="heart">♡</span>Avaamme perjantaina 20.12 klo 12 Tahkon Kerman jälleen talvikaudeksi 2025. <span className="heart">♡</span></h3>
          <div className="juhannus-text">
           Palvelemme viikot 52 ja 1 päivittäin klo 12-18, ja sunnuntaisin 12-16. <br></br> Olemme suljettuna jouluaattona. Viikko 2 alkaen palvelemme torstaista-lauantaihin klo 12-18. <br></br>Ryhmiä palvelemme sopimuksen mukaan. <br></br> <br /><span className="accent">Kiitos ja tervetuloa jälleen!</span>
          </div>

          <div className="email-phone">Tahkolahdentie 5, 73310 Tahkovuori <span className="line">| </span> ravintola@tahkonkerma.fi <span className="line">|</span> +358 50 4064012</div>
        </h4>
        
        <img src={img2} className="background-image" alt="kerma-background" />
      </div>
    </section>
  );
};

export default Closed;
