import React, { useEffect, useState } from 'react';
import Galleryheader from '../headers/gallery-header/Galleryheader';
import './wipgallery.css'
import img1 from '../../assets/biggallery/IMG_0815.JPG';
import img2 from '../../assets/biggallery/IMG_0841.JPG';
import img3 from '../../assets/biggallery/IMG_0873.JPG';
import img4 from '../../assets/biggallery/IMG_0963.JPG';
import img5 from '../../assets/biggallery/IMG_1008.JPG';
import img6 from '../../assets/biggallery/IMG_1028.JPG';
import img7 from '../../assets/biggallery/IMG_1031.JPG';
import img8 from '../../assets/biggallery/IMG_1034.JPG';
import img9 from '../../assets/1.0pics/omenapiiraka.jpg';
import img10 from '../../assets/biggallery/IMG_1037.JPG';
import img11 from '../../assets/1.0pics/lankkut.jpg';
import Navbar from '../navbar/Navbar'

const Wipgallery = () => {
  const [showAllIcons, setShowAllIcons] = useState(true);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const toggleIcons = () => {
    setShowAllIcons(!showAllIcons);
  };

  useEffect(() => {

    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (windowWidth < 500) {
      setShowAllIcons(false);
    } else {
      setShowAllIcons(true);
    }
  }, [windowWidth]);

  return (
    <section className="kerma-gallery" id="kerma-gallery">
    <div>
      <Navbar/>
      <Galleryheader/>
      <div className="gallery-container">
      <div className="gallery-images">
        <div>
          <img src={img1} alt="kerma-img1" />
        </div>
        <div className="v-stretch">
          <img src={img2}  alt="kerma-img2" />
        </div>
        <div className="h-stretch">
          <img src={img6} alt="kerma-img3" />
        </div>

        {showAllIcons && (
              <>

        <div>
          <img src={img8} alt="kerma-img4" />
        </div>
        <div>
          <img src={img11} alt="kerma-img5" />
        </div>
        <div className="v-stretch">
          <img src={img9} alt="kerma-img6" />
        </div>
        <div className="big-stretch">
          <img src={img5} alt="kerma-img7" />
        </div>
        <div>
          <img src={img10} alt="kerma-img8" />
        </div>
        <div className="h-stretch">
          <img src={img3} alt="kerma-img9" />
        </div>
        <div>
          <img src={img7} alt="kerma-img10" />
        </div>
        <div>
          <img src={img4} alt="kerma-img11" />
        </div>
        </>
            )}
      </div>
      {windowWidth < 500 && (
          <div className="dots-container" onClick={toggleIcons}>
          <span className="dot"></span>
          <span className="dot"></span>
          <span className="dot"></span>
        </div>
          )}
      </div>
    </div>
    </section>

  )
}

export default Wipgallery