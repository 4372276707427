import React from 'react';
import './galleryheader.css';
import kermabg from '../../../assets/IMG_0838.JPG';

const Galleryheader = () => {
  return (
    <div>
      <div className="menu-background-header">
        <img src={kermabg} className="menu-background-image" alt="kerma-background" />
        <div className="header-text">
          <h1 className="tervetoula-kerma">Galleria</h1>
        </div>
      </div>
    </div>
  );
};

export default Galleryheader;