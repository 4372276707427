import React from 'react';
import './drinkmenu.css';
import Footer from '../../footer/Footer';
import wine from '../../../assets/menudrinks/Wine.png';
import beer from '../../../assets/menudrinks/Beer.png';
import hotdrink from '../../../assets/menudrinks/Hotdrink.png';
import Juice from '../../../assets/menudrinks/Juice.png';
import Kahvi from '../../../assets/coffee-04.png'

const DrinkMenu = () => {
  return (
    <div className="menu-container">
      <div className="lists-container">
        <div className="list-column">
          <div className="list">
            <div className="list-header-container">
              <img src={wine} className="drinkspng" alt="wine" />
              <h1 className="list-headers"> Viinit </h1>
            </div>
            <div className="list-content">
              <div>
                <ul>
                  <h1 className="list-headers-wine"> Valkoviinit piccolo 18.75cl </h1>
                  <li className="list-item-drinks">
                  Tarapaca Sauvignon Blanc, Chile
                  <span className="list-item-price">€9.00</span>
                    <p className="wine-description"> Vaaleankeltainen valkoviini vihertävillä heijastuksilla. Tuoreet aromit nenässä, joissa sitrushedelmien vivahteita,muistuttaen pinkkiä greippiä. Maku on tyylikäs, erittäinmiellyttävällä ja tasapainoisella happamuudella</p>
                  </li>

                  <li className="list-item-drinks">
                  Lindemans Cawarra Chardonnay-Semillon, Australia
                   <span className="list-item-price">€9.00</span>
                    <p className="wine-description"> Kuiva, keskihapokas, kypsän sitruksinen, omenainen, keltaluumuinen, kevyen hunajameloninen, hennon yrttinen.</p>
                  </li>
                </ul>
                <ul>
                  <h1 className="list-headers-wine"> Punaviini Piccolo 18.75cl</h1>
                  <span className="list-type"> plo </span>
                  <li className="list-item-drinks">
                  Tarapaca Cabernet Sauvignon, Chile <span className="list-item-price">€9.00</span>
                    <p className="wine-description"> Keskitäyteläinen, tanniininen, kypsän karpaloinen, karhunvatukkainen, kirsikkainen, mustikkainen, hennon paprikainen, mausteinen</p>
                  </li>

                  <li className="list-item-drinks">
                  Lindemans Cawarra Shiraz-Cabernet, Australia <span className="list-item-price">€9.00</span>
                    <p className="wine-description"> Keskitäyteläinen, keskitanniininen, kirsikkainen, karhunvatukkainen, kevyen vaniljainen, mausteinen.</p>
                  </li>

                </ul>
              </div>
            </div>
          </div>
          <div className="list">
            <div className="list-header-container">
              <img src={Juice} className="drinkspng" alt="juice" />
              <h1 className="list-headers"> Kylmät juomat </h1>
            </div>
            <div className="list-content">
              <ul>
                <li className="list-item-beer">
                  Limu 0.25/Vichy 0.33
                  <span className="list-item-price">€4.00</span>
                </li>

                <li className="list-item-beer">
                   Iso Limu/Vichy 0.5
                  <span className="list-item-price">€5.00</span>
                </li>

                <li className="list-item-beer">
                   Tuoremehu
                  <span className="list-item-price">€4.00</span>
                </li>

                <li className="list-item-beer">
                   Pieni smoothie 
                  <span className="list-item-price">€3.00</span>
                </li>

                <li className="list-item-beer">
                   Iso smoothie
                  <span className="list-item-price">€5.00</span>
                </li>

                <li className="list-item-beer">
                   Lonkerot
                  <span className="list-item-price">€7.50</span>
                </li>

                <li className="list-item-beer">
                   Gallipette
                  <span className="list-item-price">€8.50</span>
                </li>

                <li className="list-item-beer">
                   Holiton Siideri
                  <span className="list-item-price">€7.00</span>
                </li>
                
              </ul>
              </div>
              </div>
        </div>

        <div className="list-column">
          <div className="list">
            <div className="list-header-container">
              <img src={beer} className="drinkspng" alt="beer" />
              <h1 className="list-headers"> Oluet </h1>
            </div>
            <h1 className="list-headers-beer"> Hanasta </h1>
            <div className="list-content">
              <ul>
                <li className="list-item-beer">
                  Pieni Blanc 0.25
                  <span className="list-item-price">€5.00</span>
                </li>

                <li className="list-item-beer">
                  Iso Blanc 0.5
                  <span className="list-item-price">€9.80</span>
                </li>

                <li className="list-item-beer">
                  Pieni Kronenbourg 0.25
                  <span className="list-item-price">€5.00</span>
                </li>

                <li className="list-item-beer">
                  Iso Kronenbourg 0.5
                  <span className="list-item-price">€9.80</span>
                </li>

                <li className="list-item-beer">
                  Pieni Staropramen 0.33
                  <span className="list-item-price">€6.00</span>
                </li>

                <li className="list-item-beer">
                  Iso Staropramen 0.5
                  <span className="list-item-price">€9.80</span>
                </li>
                
              </ul>

              <h1 className="list-headers-beer"> Pullosta </h1>
              <ul>
                <li className="list-item-beer">
                  Karhu Platina
                  <span className="list-item-price">€6.50</span>
                </li>
                <li className="list-item-beer">
                  Holiton Olut
                  <span className="list-item-price">€7.00</span>
                </li>
               
              </ul>
            </div>
          </div>

          <div className="list-column">
          <div className="list">
            <div className="list-header-container">
              <img src={Kahvi} className="drinkspng" alt="beer" />
              <h1 className="list-headers"> Kahvi / Tee</h1>
            </div>
            <div className="list-content">
              <ul>
                <li className="list-item-beer">
                  Pieni Kahvi
                  <span className="list-item-price">€2.00</span>
                </li>
                <li className="list-item-beer">
                  Iso Kahvi
                  <span className="list-item-price">€3.00</span>
                </li>
                
                <li className="list-item-beer">
                  Tee (vihrea, musta)
                  <span className="list-item-price">€3.00</span>
                </li>
                <li className="list-item-beer">
                  Espresso
                  <span className="list-item-price">€4.00</span>
                </li>

                <li className="list-item-beer">
                  Tupla Espresso
                  <span className="list-item-price">€4.50</span>
                </li>

                <li className="list-item-beer">
                  Cappuccino
                  <span className="list-item-price">€5.00</span>
                </li>
               
                <li className="list-item-beer">
                  Latte
                  <span className="list-item-price">€5.50</span>
                </li>

                <li className="list-item-beer">
                  Jääkahvi
                  <span className="list-item-price">€6.50</span>
                </li>

              </ul>
            </div>
          </div>

          <div className="list">
            <div className="list-header-container">
              <img src={hotdrink} className="drinkspng" alt="hotdrink" />
              <h1 className="list-headers"> Kuumat juomat </h1>
            </div>
            <div className="list-content">
              <ul>
                <li className="list-item-cocktail">
                  Kaakao
                  <span className="list-item-price">€3.00</span>
                </li>

                <li className="list-item-cocktail">
                  Minttukaakao
                  <span className="list-item-price">€10.00</span>
                </li>

                <li className="list-item-cocktail">
                  Jallukaakao
                  <span className="list-item-price">€10.00</span>
                </li>

                <li className="list-item-cocktail">
                  Rommitoti / Kaakao
                  <span className="list-item-price">€10.00</span>
                </li>

                <li className="list-item-cocktail">
                  Kuumamehu
                  <span className="list-item-price">€3.00</span>
                </li>

                <li className="list-item-cocktail">
                  Glögi
                  <span className="list-item-price">€9.50</span>
                </li>

                <li className="list-item-cocktail">
                  Holiton Glögi
                  <span className="list-item-price">€4.50</span>
                </li>

                <li className="list-item-beer">
                  Irish Coffee
                  <span className="list-item-price">€10.00</span>
                </li>
                
              </ul>
            </div>
          </div>
          </div>

        </div>
      </div>
      <Footer />
    </div>
  );
};

export default DrinkMenu;
